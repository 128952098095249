/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-i18n-at-module-eval */
import '../../moment/moment-and-timezone-init';
import { I18nInternal as I18n } from '../../../internal/i18n-internal';
import { momentLocaleMapper, momentMappings, defaultLanguage, getLangEnabledLocales } from '../../internal/legacyI18nInit';
// To do: We currently have to point to an arbitrary locale.
// Need extra lazy loader
// @ts-expect-error `moment/locale/es` is untyped
import momentLocale from 'i2l?mode=very-lazy!moment/locale/es';
export default (Provider => Provider.register(momentLocale, {
  localeMapper: momentLocaleMapper,
  getLocales: getLangEnabledLocales
}).then(() => {
  const validMomentLocale = momentMappings[I18n.locale] || momentMappings[I18n.locale.substring(0, 2)] || defaultLanguage;
  return I18n.moment.locale(validMomentLocale);
}));