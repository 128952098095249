/**
 * @param maxVisiblePageButtons
 * @param page
 * @param pageCount
 * @returns A tuple containing the minimum and maximum page numbers to be displayed in the paginator.
 */
export const getMinAndMaxPageNumbers = (maxVisiblePageButtons, page, pageCount) => {
  // the number of buttons to show on either side of the current page number
  const buffer = (maxVisiblePageButtons - 1) / 2;
  let min = page - buffer;
  let max = page + buffer;

  // Adjust min and max to ensure they are within the valid range
  const numPagesToShow = Math.min(maxVisiblePageButtons, pageCount);
  if (min < 1) {
    min = 1;
    max = min + maxVisiblePageButtons - 1;
  }
  if (max > pageCount) {
    max = Math.max(pageCount, 1);
    min = max - numPagesToShow + 1;
  }
  return [Math.floor(min), Math.floor(max)];
};