/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT from constants.ts.
 */
export const globalNavHeightNumber = '54';

/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT from constants.ts.
 */
export const globalNavHeight = '54px';

/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT from constants.ts.
 */
export default globalNavHeight;