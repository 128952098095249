import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { getDataFetchingClientRequestState } from 'marketplace-ui-common/dataFetchingClient/utils/getDataFetchingClientRequestState';
import getLang from 'I18n/utils/getLang';
import { GET_REVIEW_STATS } from '../queries/getReviewStatsQuery';
import { GET_REVIEWS } from '../queries/getReviewsQuery';
import Review from '../records/Review';
import ReviewStats from '../records/ReviewStats';
export function useReviews({
  appContext,
  reviewFilters,
  offeringId,
  reviewType
}) {
  const language = getLang();
  const {
    data: reviewsData,
    loading: reviewsLoading,
    error: reviewsError
  } = useQuery(GET_REVIEWS, {
    variables: {
      appContext: appContext === 'admin-preview' ? 'public' : appContext,
      reviewType,
      queries: Object.assign({}, reviewFilters.toSearchQuery(), {
        language
      })
    },
    skip: !reviewFilters.entityId
  });
  const {
    data: reviewStatsData,
    loading: reviewStatsLoading,
    error: reviewStatsError
  } = useQuery(GET_REVIEW_STATS, {
    variables: {
      appContext: appContext === 'admin-preview' ? 'public' : appContext,
      offeringId,
      reviewType
    }
  });
  const {
    reviewStats,
    reviewStatsFetchStatus
  } = useMemo(() => {
    return {
      reviewStats: new ReviewStats(reviewStatsData && reviewStatsData.reviewStatsResponse || null).set('reviewType', reviewType),
      reviewStatsFetchStatus: getDataFetchingClientRequestState(reviewStatsError, reviewStatsLoading)
    };
  }, [reviewStatsData, reviewStatsError, reviewStatsLoading, reviewType]);
  const reviewsCount = reviewStats.get('count') || 0; // All reviews
  const {
    reviews,
    reviewsTotal,
    // Based on current filters
    reviewsFetchStatus
  } = useMemo(() => ({
    reviews: Review.fromArray(reviewsData && reviewsData.reviewsResponse.reviews || [], reviewType),
    reviewsTotal: reviewsData && reviewsData.reviewsResponse.total || 0,
    reviewsFetchStatus: getDataFetchingClientRequestState(reviewsError, reviewsLoading)
  }), [reviewType, reviewsData, reviewsError, reviewsLoading]);
  return {
    reviews,
    reviewStats,
    reviewsFetchStatus,
    reviewStatsFetchStatus,
    reviewsTotal,
    reviewsCount
  };
}