import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'data-fetching-client';
import Raven from 'raven-js';
import unescapedText from 'I18n/utils/unescapedText';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { FloatingAlertStore } from 'foundations-components/transitional/components/FloatingAlertList';
import { GET_DISPLAY_NAME } from '../queries/reviewerDisplayNameQuery';
import { GET_USER_INSIGHTS } from '../queries/userInsightsQuery';
import { SUBMIT_REVIEW } from '../queries/submitReviewQuery';
import { EDIT_REVIEW } from '../queries/editReviewQuery';
import { GET_EDITABILITY } from '../queries/editabilityQuery';
import EcosystemReview from '../records/Review';
export function useEcosystemReviews({
  customValidationErrors,
  editReason,
  errorAlertMessage,
  errorAlertTitle,
  isEditing,
  offeringId,
  onChange,
  onFail,
  onSuccess,
  originalReview,
  reviewFormatter,
  reviewId,
  reviewType,
  overrideSubmitValidation,
  successAlertMessage,
  successAlertTitle,
  validationSchema
}) {
  const [submitReview] = useMutation(SUBMIT_REVIEW);
  const [editReview] = useMutation(EDIT_REVIEW);
  const [hasEdits, setHasEdits] = useState(false);
  const [displayName, setDisplayName] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [marketplaceIndustry, setMarketplaceIndustry] = useState(null);
  const [formattedValidationSchema, setFormattedValidationSchema] = useState(validationSchema);
  const {
    data: displayNameData
  } = useQuery(GET_DISPLAY_NAME);
  const {
    data: userInsightsData
  } = useQuery(GET_USER_INSIGHTS);
  const {
    data: editabilityData
  } = useQuery(GET_EDITABILITY, {
    variables: {
      reviewId
    },
    skip: !isEditing
  });
  useEffect(() => {
    if (displayNameData) {
      const {
        displayName: {
          displayName: fetchedDisplayName
        }
      } = displayNameData;
      setDisplayName(fetchedDisplayName);
    }
  }, [displayNameData]);
  useEffect(() => {
    if (userInsightsData) {
      const {
        employeeSize,
        industry: fetchedIndustry
      } = userInsightsData.userInsights.portalInsights;
      setEmployees(employeeSize || null);
      setMarketplaceIndustry(fetchedIndustry || null);
    }
  }, [userInsightsData]);
  useEffect(() => {
    const newValidationSchema = structuredClone(validationSchema);
    if (editabilityData) {
      Object.entries(editabilityData.editability).forEach(([reviewField, editability]) => {
        const schemaKey = reviewField === 'answers' ? 'content' : reviewField;
        if (!newValidationSchema[schemaKey]) {
          newValidationSchema[schemaKey] = {};
        }
        Object.keys(editability).forEach(fieldKey => {
          newValidationSchema[schemaKey][fieldKey] = Object.assign({}, newValidationSchema[schemaKey][fieldKey], {
            locked: !editability[fieldKey]
          });
        });
      });
    }
    setFormattedValidationSchema(newValidationSchema);
  }, [editabilityData, validationSchema]);
  const handleChange = useCallback(review => {
    if (isEditing && originalReview) {
      let hasChanges = false;
      Object.entries(review).forEach(([typeKey, typeObj]) => {
        Object.entries(typeObj).forEach(([key, value]) => {
          if (!originalReview[typeKey][key] && value) {
            hasChanges = true;
          } else if (value && originalReview[typeKey][key] !== value) {
            hasChanges = true;
          }
        });
      });
      setHasEdits(hasChanges);
    }
    if (onChange) {
      onChange(review);
    }
  }, [isEditing, onChange, originalReview]);
  const handleSubmit = useCallback(review => {
    let filteredReview = structuredClone(review);
    Object.entries(filteredReview.content).forEach(([key, value]) => {
      if (!value) {
        delete filteredReview.content[key];
      }
    });
    if (reviewFormatter) {
      filteredReview = reviewFormatter(filteredReview);
    }
    let submittedReview = new EcosystemReview(Object.assign({}, filteredReview, {
      answers: filteredReview.content
    })).set('createdAt', new Date().valueOf()).set('entityId', offeringId).set('reviewType', reviewType).set('reviewerDisplayName', displayName).set('employees', employees).set('marketplaceIndustry', marketplaceIndustry);
    if (isEditing && reviewId) {
      submittedReview = submittedReview.set('id', reviewId);
    }
    const submitFunction = isEditing ? editReview : submitReview;
    submitFunction({
      variables: {
        review: submittedReview,
        editReason
      }
    }).then(() => {
      // Success alert
      FloatingAlertStore.addAlert({
        message: successAlertMessage || unescapedText('marketplace-ui-reviews.wizard.alert.submit.message'),
        titleText: successAlertTitle || unescapedText('marketplace-ui-reviews.wizard.alert.submit.title'),
        type: 'success'
      });
      if (onSuccess) {
        onSuccess(filteredReview);
      }
    }).catch(e => {
      // Failure alert
      let message = errorAlertMessage || unescapedText('marketplace-ui-reviews.wizard.alert.error.message');
      try {
        const error = JSON.parse(e.data).subCategory;
        if (isValidI18nKey(`marketplace-ui-reviews.error.${error}`)) {
          message = unescapedText(`marketplace-ui-reviews.error.${error}`);
        }
      } catch (err) {
        Raven.captureMessage('subCategory not provided in backend error response ', {
          level: 'warning',
          extra: {
            error: err
          }
        });
      }
      FloatingAlertStore.addAlert({
        message,
        titleText: errorAlertTitle || unescapedText('marketplace-ui-reviews.wizard.alert.error.title'),
        type: 'danger'
      });
      if (onFail) {
        onFail();
      }
    });
  }, [displayName, editReason, editReview, employees, errorAlertMessage, errorAlertTitle, isEditing, offeringId, marketplaceIndustry, onFail, onSuccess, reviewFormatter, reviewId, reviewType, successAlertMessage, successAlertTitle, submitReview]);
  const formattedCustomValidationErrors = customValidationErrors ? [...customValidationErrors] : [];
  if (!overrideSubmitValidation && isEditing && !hasEdits) {
    formattedCustomValidationErrors.push(unescapedText('marketplace-ui-reviews.wizard.customValidation.noEdits'));
  }
  if (!overrideSubmitValidation && isEditing && !editReason) {
    formattedCustomValidationErrors.push(unescapedText('marketplace-ui-reviews.wizard.customValidation.noEditReason'));
  }
  return {
    formattedCustomValidationErrors,
    formattedValidationSchema,
    handleChange,
    handleSubmit
  };
}