import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["sort", "filter", "industry", "employees"];
import produce from 'immer';
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { cleanObject } from 'marketplace-ui-common/utils/formatters';
import * as LIST_QUERIES from '../constants/ReviewsListQueries';
const reviewFiltersDefaults = {
  entityId: null,
  reviewTypes: null,
  reviewerPortalId: null,
  reviewerUserId: null,
  service: null,
  sort: LIST_QUERIES.SORT.NEWEST
};
export default class ReviewFilters extends createImmutableObjectClass(reviewFiltersDefaults, {
  fromJSON: _ref => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
    return Object.assign({
      overallRating: null,
      limit: LIST_QUERIES.REVIEWS_PER_PAGE,
      page: 1,
      filter: LIST_QUERIES.FILTER.ALL,
      industry: null,
      employees: null,
      search: ''
    }, rest);
  },
  toJSON: _ref2 => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
    return Object.assign({}, rest);
  }
}) {
  toSearchQuery() {
    const {
      STATE_TO_QUERY
    } = LIST_QUERIES;
    const searchQuery = Object.assign({}, cleanObject(this.toJS()), STATE_TO_QUERY[this.get('filter')], STATE_TO_QUERY[this.get('sort')], {
      offset: this.get('page') * this.get('limit') - this.get('limit')
    });
    if (!this.get('search')) {
      delete searchQuery.search;
    }
    if (this.get('industry')) {
      searchQuery.marketplaceIndustries = {
        clause: 'OR',
        values: [this.get('industry')]
      };
    }
    if (this.get('employees')) {
      searchQuery.employeeSizes = {
        clause: 'OR',
        values: [this.get('employees')]
      };
    }
    if (this.get('service')) {
      searchQuery.catalogServices = {
        clause: 'OR',
        values: [this.get('service')]
      };
    }
    const trimmedSearchQuery = _objectWithoutPropertiesLoose(searchQuery, _excluded);
    return trimmedSearchQuery;
  }
  updateFilters(updates) {
    return produce(this, reviewFiltersDraft => {
      Object.entries(updates).forEach(([key, value]) => {
        reviewFiltersDraft.set(key, value);
      });
    });
  }
}