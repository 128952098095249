// Determine the maximum value of scrollTop for this element
const getMaxScrollTop = el => el.scrollHeight - el.clientHeight;

// Scroll each target in the array by the given delta
function applyScrollDeltas(deltasToApply) {
  deltasToApply.forEach(({
    scrollTarget,
    delta
  }) => {
    scrollTarget.scrollTop += delta;
  });
}

// Prevent a wheel event from causing scrolling outside of the given container
export function constrainVerticalWheelEvent(evt, containerEl) {
  const {
    deltaMode,
    deltaY
  } = evt;
  const target = evt.target;

  // Ignore this event if it's not vertical or if deltaMode isn't "pixel"
  if (deltaMode !== 0 || deltaY === 0) {
    return;
  }

  // Ignore this event if the given container doesn't exist in the DOM
  if (!document.body.contains(containerEl)) {
    return;
  }

  // Ignore this event if it bubbled up from a portal (#5563)
  if (!containerEl.contains(target)) {
    return;
  }
  const deltasToApply = [];
  let scrollTarget = target;
  let remainingDelta = deltaY;

  // Track how much each element within the panel should be scrolled
  while (scrollTarget && scrollTarget !== containerEl) {
    const scrollTop = scrollTarget.scrollTop;
    if (remainingDelta > 0) {
      const maxScrollTop = getMaxScrollTop(scrollTarget);
      if (scrollTop + remainingDelta <= maxScrollTop) {
        return; // This element will "absorb" all scrolling
      }
      const delta = maxScrollTop - scrollTop;
      deltasToApply.push({
        scrollTarget,
        delta
      });
      remainingDelta -= delta;
    } else {
      if (scrollTop + remainingDelta >= 0) {
        return; // This element will "absorb" all scrolling
      }
      const delta = -scrollTop;
      deltasToApply.push({
        scrollTarget,
        delta
      });
      remainingDelta -= delta;
    }
    scrollTarget = scrollTarget.parentElement;
  }

  // At this point, the only element left is the panel itself
  const scrollTop = containerEl.scrollTop;
  const maxScrollTop = containerEl.scrollHeight - containerEl.clientHeight;
  if (scrollTop + remainingDelta >= maxScrollTop) {
    containerEl.scrollTop = maxScrollTop;
    evt.preventDefault();
    applyScrollDeltas(deltasToApply);
  } else if (scrollTop + remainingDelta <= 0) {
    containerEl.scrollTop = 0;
    evt.preventDefault();
    applyScrollDeltas(deltasToApply);
  }
}