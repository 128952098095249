import { useCallback, useState } from 'react';
import { PAGE_KEY } from '../constants/ReviewsListQueries';
import ReviewFilters from '../records/ReviewFilters';
export function useReviewFilters({
  entityId,
  reviewTypes
}) {
  const [reviewFilters, setReviewFilters] = useState(new ReviewFilters({
    entityId,
    reviewTypes
  }));
  const updateReviewFilters = useCallback(updates => {
    let newFilters = reviewFilters.updateFilters(updates);
    if (!updates[PAGE_KEY]) {
      newFilters = newFilters.updateFilters({
        [PAGE_KEY]: 1
      });
    }
    setReviewFilters(newFilters);
  }, [reviewFilters]);
  return {
    reviewFilters,
    updateReviewFilters
  };
}