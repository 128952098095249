import { useMemo } from 'react';
import { parse as parseParams } from 'hub-http/helpers/params';
export function useShortlinkParams(trackingParams = {}) {
  const {
    search
  } = window.location;
  const shortlinkParams = useMemo(() => {
    const currentParams = parseParams(search.slice(1));
    return Object.assign({}, trackingParams, currentParams);
  }, [search, trackingParams]);
  return shortlinkParams;
}