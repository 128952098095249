import { WebsiteIcon, CustomModulesIcon, IntegrationsIcon, ReportsIcon, SalesQuoteIcon, TagIcon, EditIcon, WorkflowsIcon, LogEmailIcon, CapIcon, ContactDuplicateIcon, SalesTemplatesIcon } from 'foundations-assets';
export const CAROUSEL_SERVICES_QA = [{
  id: 1309,
  icon: WebsiteIcon
},
// website development
{
  id: 1308,
  icon: CustomModulesIcon
},
// website design
{
  id: 1292,
  icon: IntegrationsIcon
},
// custom api integrations
{
  id: 48553,
  icon: ReportsIcon
},
// account based marketing
{
  id: 1301,
  icon: SalesQuoteIcon
},
// paid advertising
{
  id: 1298,
  icon: TagIcon
},
// full inbound marketing services
{
  id: 1288,
  icon: EditIcon
},
// content creation
{
  id: 1305,
  icon: WorkflowsIcon
},
// sales enablement
{
  id: 1297,
  icon: LogEmailIcon
},
// email marketing
{
  id: 1304,
  icon: CapIcon
},
// sales coaching and training
{
  id: 1293,
  icon: ContactDuplicateIcon
},
// customer marketing
{
  id: 1303,
  icon: SalesTemplatesIcon
} // sales and marketing alignment
];
export const CAROUSEL_SERVICES_PROD = [{
  id: 24,
  icon: WebsiteIcon
},
// website development
{
  id: 23,
  icon: CustomModulesIcon
},
// website design
{
  id: 7,
  icon: IntegrationsIcon
},
// custom api integrations
{
  id: 39001,
  icon: ReportsIcon
},
// account based marketing
{
  id: 16,
  icon: SalesQuoteIcon
},
// paid advertising
{
  id: 13,
  icon: TagIcon
},
// full inbound marketing services
{
  id: 3,
  icon: EditIcon
},
// content creation
{
  id: 20,
  icon: WorkflowsIcon
},
// sales enablement
{
  id: 12,
  icon: LogEmailIcon
},
// email marketing
{
  id: 19,
  icon: CapIcon
},
// sales coaching and training
{
  id: 8,
  icon: ContactDuplicateIcon
},
// customer marketing
{
  id: 1,
  icon: SalesTemplatesIcon
} // sales and marketing alignment
];
export const ACCREDITATION_CARD_IDS = [43003,
// CRM Implementation
52001,
// HubSpot Onboarding
43004,
// Advanced CMS Implementation
78002,
// Custom Integration
126001,
// Data Migration
63001,
// Platform Enablement
126002 // Solution Architecture Design
];