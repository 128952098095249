import { FavoriteIcon, FavoriteHollowIcon, MinusIcon } from 'foundations-assets';
export const STAR_RATING = 'STAR_RATING';
export const STAR_RATING_FILLED = 'STAR_RATING_FILLED';
export const STAR_RATING_EMPTY_STATE = 'STAR_RATING_EMPTY_STATE';
export const PILL_RATING = 'PILL_RATING';
export const RatingTypesTemplate = {
  STAR_RATING: {
    checkedColor: 'fill-accent-yellow-default',
    uncheckedColor: 'icon-primary-disabled',
    checkedIcon: FavoriteIcon,
    uncheckedIcon: FavoriteHollowIcon
  },
  STAR_RATING_FILLED: {
    checkedColor: 'fill-accent-yellow-default',
    uncheckedColor: 'border-core-subtle',
    checkedIcon: FavoriteIcon,
    uncheckedIcon: FavoriteIcon
  },
  STAR_RATING_EMPTY_STATE: {
    checkedColor: 'border-core-subtle',
    uncheckedColor: 'border-core-subtle',
    checkedIcon: FavoriteIcon,
    uncheckedIcon: FavoriteIcon
  },
  PILL_RATING: {
    checkedColor: 'icon-tertiary-default',
    uncheckedColor: 'border-core-subtle',
    checkedIcon: MinusIcon,
    uncheckedIcon: MinusIcon
  }
};
export const LabelSizesTemplate = {
  sm: 'detail-100',
  small: 'detail-100',
  md: 'body-100',
  medium: 'body-100',
  large: 'body-200',
  lg: 'body-200'
};